@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./theme.scss";

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../fonts/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SF Mono";
  src: url("../fonts/SFMonoRegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.ant-layout,
.ant-layout {
  .graphiql-container {
    * {
      box-sizing: content-box;
    }
  }
}

$shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

.ant-table-content {
  overflow: auto;
}

.ant-tabs-tab-btn {
  font-weight: 600 !important;
}

header.ant-layout-header {
  box-shadow: $shadow;
}

header .ant-menu-item {
  border-bottom: none !important;
}

h3.ant-typography,
h2.ant-typography {
  font-weight: normal;
}

button.ant-btn {
  font-weight: normal;
}

form.ant-form:not(.ant-form-inline) {
  display: flex;
  flex-direction: column;
}

.ant-dropdown-menu-item:hover {
  background-color: initial;
  color: $primary-color;
}

.ant-dropdown-menu-item-disabled:hover {
  color: $disabled-color;
}

.ant-dropdown-menu-item > a:hover {
  color: $primary-color;
}

.ant-dropdown-menu-item-disabled > a {
  color: $disabled-color;

  &:hover {
    color: $disabled-color;
    cursor: not-allowed;
  }
}

.ant-popover-title {
  min-width: initial;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow:
    0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

// Make disabled primary buttons light blue instead of grey
.ant-btn-primary[disabled] {
  color: #fff;
  background-color: rgba(0, 119, 238, 0.3);
  &:hover,
  &:focus {
    color: #fff;
    background-color: rgba(0, 119, 238, 0.3);
  }
}

/******************************************************************************
 scrollbar
******************************************************************************/
/* Firefox, eventually adopted elsewhere */
* {
  scrollbar-width: thin;
  scrollbar-color: theme("colors.gray.300") theme("colors.white");
}
[data-theme="dark"] * {
  scrollbar-color: theme("colors.gray.700") theme("colors.black");
}
/* Chrome/Edge/Safari, eventually phase out */
*::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}
*::-webkit-scrollbar-button,
*::-webkit-scrollbar-corner {
  display: none;
}
*::-webkit-scrollbar-thumb {
  background-color: theme("colors.gray.300");
  border-radius: 6px;
}
[data-theme="dark"] *::-webkit-scrollbar-thumb {
  background-color: theme("colors.gray.700");
}
*::-webkit-scrollbar-track {
  background: theme("colors.white");
}
[data-theme="dark"] *::-webkit-scrollbar-track {
  background-color: theme("colors.black");
}

@layer utilities {
  .stroke-round {
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
